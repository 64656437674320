import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Material Dashboard 2 React contexts
import CustomItem from "./posicaoChart";
// import { getProperty } from "layouts/dashboard/Api/dashboardApi";
import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import { getProperty } from "layouts/dashboard/api/dashboardApi";
import { useMaterialUIController } from "context";

function Posicao() {
    const [globalPosition, setGlobalPosition] = useState([]);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    useEffect(() => {
        getAssetsPosition();
    }, []);

    const getAssetsPosition = async () => {
        await getProperty()
            .then(e => {
                setGlobalPosition(e.map(e => ({ name: e.name, value: e.value })));
            });
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <MDBox pt={2} px={2} lineHeight={1.25}>
                    <MDTypography variant="h6" fontWeight="medium">
                        Posição Global
                    </MDTypography>
                    <MDBox mb={1}>
                        <MDTypography variant="button" color="text">
                            Gráfico da posição patrimonial global
                        </MDTypography>
                    </MDBox>
                </MDBox>
                <CustomItem data={globalPosition} isDarkMode={darkMode} />
            </Card>

            {/* 

            Posição Por categoria
            <CustomItem data={data} /> */}
            <MDBox mb={2} />
            <Footer />
        </DashboardLayout>
    );
}

export default Posicao;
