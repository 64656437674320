import CustomFetch from "components/API/customFetch";

const getAssets = async (pageSize = 10, pageIndex = 1, orderBy = null, order = null) => {
    const url = new URL(`${process.env.REACT_APP_API}/Operations/position`);

    const requestOptions = {
        method: 'GET',
    };

    const params = {
        pageSize: pageSize ?? null,
        pageIndex: pageIndex ?? null,
        orderBy: orderBy,
        order: order
    };

    // Adiciona os parâmetros à URL
    Object.keys(params).forEach(key => {
        if (params[key] !== null) {
            url.searchParams.append(key, params[key]);
        }
    });

    try {
        const response = await CustomFetch(url, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    }
};

export const getProperty = async () => {
    const requestOptions = {
        method: 'GET',
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/Operations/property`, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    }
};

export const getTotalPropertyValue = async () => {
    const requestOptions = {
        method: 'GET',
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/Operations/total-property-value`, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    }
};

export const getOperationsExtract = async (pareIndex, pageSize, search, startDate, endDate) => {
    const requestOptions = {
        method: 'GET',
    };

    try {
        const response =
            await CustomFetch(`${process.env.REACT_APP_API}/Operations/operations-extract?pageSize=${pageSize}&pageIndex=${pareIndex}&startDate=${startDate}&endDate=${endDate}&search=${search}
            `
                , requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    }
};

export default getAssets;