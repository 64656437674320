// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import GoogleIcon from "@mui/icons-material/Google";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bgg.jpg";
import { useState } from "react";
import { authenticateGoogleUser, register } from "../api/authenticationApi";
import styled from "styled-components";
import { CircularProgress, Typography } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import GoogleLogin from "react-google-login";

function Cover() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [isLoading, setIsLoagding] = useState(false);
  const [errors, setErrors] = useState({ name: '', email: '', pass: '' });
  const [openSnackBar, setOpenSnackBar] = useState({ open: false, message: '' })

  const clientId = "429303876753-fijpsiil2qt0amasetqsj1320a38rmj6.apps.googleusercontent.com";


  const navigate = useNavigate();

  const handleSignIn = async () => {
    if (!validateForm()) return;

    setIsLoagding(true);
    await register(name, email, pass)
      .then(async e => {

        const res = await e.json();
        if (e.status == 200) {
          localStorage.setItem("tradez-token", `${res.token.accessToken}`);
          navigate('/dashboard');
        } else {
          setOpenSnackBar({ open: true, message: res?.message })
        }
      })
      .catch(e => {
        console.log(e);
      })
      .finally(e => setIsLoagding(false))
  }

  const validateForm = () => {
    let valid = true;
    const newErrors = { name: '', email: '', pass: '' };

    if (!name) {
      newErrors.name = "Nome é obrigatório";
      valid = false;
    }

    if (!email) {
      newErrors.email = "Email é obrigatório";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Formato de email inválido";
      valid = false;
    }

    if (!pass) {
      newErrors.pass = "Senha é obrigatória";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const responseGoogle = (response) => {
    const { profileObj: { email, name, imageUrl } } = response;

    authenticateUser(email, name, imageUrl);
  }

  const authenticateUser = async (userName, name, image) => {
    setIsLoagding(true);
    await authenticateGoogleUser(userName, name, image)
      .then(e => {

        if (e.status == 200) {
          return e.json();
        }
      })
      .then(e => {
        localStorage.setItem("tradez-token", `${e.token.accessToken}`);
        if (e.isNewUser) {
          navigate('/pagamento');
        } else {
          navigate('/dashboard');
        }
      })
      .catch(e => console.log(e))
      .finally(() => setIsLoagding(false))
  }


  return (
    <CoverLayout image={bgImage}>
      <StyledCard className="StyledCard">
        <StyledContent
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDBox mt={-2} mb={-4}>
            <img width={250} src={`../p3602.png`} />
          </MDBox>
        </StyledContent>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                onChange={e => setName(e.target.value)}
                type="text"
                label="Nome"
                variant="standard"
                fullWidth
                error={!!errors.name} />
              {!!errors.name && <StyledSpan>{errors.name}</StyledSpan>}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                onChange={e => setEmail(e.target.value)}
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                error={!!errors.email} />
              {!!errors.email && <StyledSpan>{errors.email}</StyledSpan>}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                onChange={e => setPass(e.target.value)}
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                error={!!errors.pass} />
              {!!errors.pass && <StyledSpan>{errors.pass}</StyledSpan>}
            </MDBox>


            {/* 
              //TODO: 
              //Concordo com os termos 
              //*/}

            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Concordo com os&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Termos
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton disabled={isLoading} onClick={handleSignIn} variant="gradient" color="info" fullWidth>
                {isLoading
                  ? <CircularProgress color="inherit" size={16} />
                  :
                  'Registrar'}
              </MDButton>

              <StyledDiv>

                <MDTypography variant="caption" color="text" fontWeight="regular">
                  ou
                </MDTypography>
              </StyledDiv>


              <GoogleLogin
                clientId={clientId}
                onSuccess={responseGoogle}
                render={renderProps => (
                  <MDButton disabled={isLoading} onClick={renderProps.onClick} variant="gradient" color="error" fullWidth>
                    {isLoading
                      ? <CircularProgress color="inherit" size={16} />
                      :
                      <GoogleIcon color="inherit" />
                    }
                  </MDButton>
                )}
              // onFailure={responseGoogle}
              // icon={false}
              // cookiePolicy={'single_host_origin'} //testar isso aqui
              />
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Já tem cadastro?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Login
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </StyledCard>
      {/* <Grid item xs={12} sm={6} lg={3}> */}
      <MDSnackbar
        color="error"
        icon="check"
        title={openSnackBar?.message}
        open={openSnackBar.open}
        onClose={() => setOpenSnackBar({ open: false, message: '' })}
        close={() => setOpenSnackBar({ open: false, message: '' })}
      // bgWhite
      />
      {/* </Grid> */}
    </CoverLayout>
  );
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  padding: 6px;
`;

const StyledContent = styled(MDBox)`
background: radial-gradient(circle at 135% 631%, #f28371 79%, #f28371 1%, transparent 0%) ,#f0ba8d !important; 

display: flex;
justify-content: center;

@media screen and (max-width: 405px) {
  img {
    width: 220px
  }
}

@media screen and (max-height: 790px) {
  img {
    width: 190px
  }
}
`;

const StyledCard = styled(Card)`
@media screen and (max-height: 790px) {
  margin-top: -123px !important;
}

`;

const StyledSpan = styled.span`
  color: red;
  font-size:10px;
  display:flex;
`;


export default Cover;
