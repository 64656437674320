import React, { useEffect, useState } from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { getAssetsCurrentAndTargetList } from '../../api/allocationApi';
import { useMaterialUIController } from 'context';

function AlocacaoPorAtivo() {
    const [data, setData] = useState([]);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        await getAssetsCurrentAndTargetList()
            .then(res => setData(res?.map(e => {
                return {
                    ativo: e.asset,
                    atual: e.currentPercentage,
                    meta: e.targetPercentage,
                    // valorAtual: 2, 
                    // valorMeta: 3
                }
            })))
    }

    return (
        <div style={{ width: '100%', height: 600 }}>
            <StyledResponsiveContainer width="100%" height="100%" className="bars-chart-resp" $isDarkMode={darkMode}>
                <BarChart
                    barCategoryGap={'35%'}
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="ativo" />
                    <YAxis />
                    <Tooltip 
                        formatter={(value) => `${value}%`}
                    />

                    {/* <Tooltip 
                    content={({ payload, label }) => {
                        if (!payload || payload.length === 0) return null;

                        const { atual, meta, valorAtual, valorMeta } = payload[0].payload;

                        return (
                            <div style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                                <p><strong>{label}</strong></p>
                                <p>Atual: {atual}%</p>
                                <p>Meta: {meta}%</p>
                                <p>Valor Atual: R$ {valorAtual?.toFixed(2)}</p>
                                <p>Valor Meta: R$ {valorMeta?.toFixed(2)}</p>
                            </div>
                        );
                    }}
                /> */}
                    <Legend />

                    <Bar dataKey="atual" fill="#8884d8" activeBar={<Rectangle fill="#8884d8" stroke="black" />} />
                    <Bar dataKey="meta" fill="#82ca9d" activeBar={<Rectangle fill="#82ca9d" stroke="black" />} />
                    
                </BarChart>
            </StyledResponsiveContainer>
        </div>
    );
}


const StyledResponsiveContainer = styled(ResponsiveContainer)`
    .recharts-text.recharts-cartesian-axis-tick-value tspan{
        fill: ${({ $isDarkMode }) => ($isDarkMode ? "#ffffffcc" : "#344767")};
    }

    // g.recharts-layer.recharts-bar-rectangle path.recharts-rectangle{
    //     transform: scaleX(0.5);
    // }
`;

export default AlocacaoPorAtivo;