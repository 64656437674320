import { Card, Grid, Pagination, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useDebounce } from 'use-lodash-debounce';

import 'dayjs/locale/pt-br';
import Transaction from "layouts/receitaXdespesa/components/Transaction";
import { getOperationsExtract as getExtract } from "layouts/dashboard/api/dashboardApi";
import EditOperationForm from "./editOperationForm";
import KeepMountedModal from "components/Modal";
import MDSnackbar from "components/MDSnackbar";
import SearchInput from "components/SearchInput";
import DateRangePicker from "components/DateRangePicker";

function OperationsExtratoLayout() {
    const [data, setData] = useState([]);
    const [openSnackMessage, setOpenSnackMessage] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [id, setId] = useState(null);
    const [reloadList, setReloadList] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [dateRange, setDateRange] = useState({});
    const [okButtonClickd, setOkButtonClickd] = useState(false);

    const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        getOperationsExtract();
    }, [, reloadList, currentPage, debouncedSearch, okButtonClickd]);

    const getOperationsExtract = async () => {
        setIsLoading(true);
        await getExtract(currentPage, 10, debouncedSearch, dateRange?.startDate ?? '', dateRange?.endDate ?? '')
            .then((e) => {
                setData(e)
            });

        setIsLoading(false);
    }

    const handleOpenModal = (id) => {
        setId(id);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleSearchChange = (search) => {
        setSearch(search);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Card sx={{ height: "100%" }}>
                <StyledMdBoxMain className="header-extract" display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        Extrato
                    </MDTypography>
                    <MDBox display="flex" alignItems="flex-start">
                        <MDBox className="search-input-div" px={2}>
                            <SearchInput handleSearchChange={(e) => handleSearchChange(e)} />
                        </MDBox>
                        <MDBox className="calendar-input-div" color="text" mr={0.5} lineHeight={0}>
                            <DateRangePicker setDateRange={setDateRange}
                                okButtonClicked={() => setOkButtonClickd(!okButtonClickd)}
                                resetValues={() => setReloadList(!reloadList)}
                            />
                        </MDBox>
                        <MDTypography variant="button" color="text" fontWeight="regular">
                            {/* ajustar aqui */}
                            {/* 01 - {revenuesXExpensesList[0]?.items[0]?.lastDayOfMonth}
                            {revenuesXExpensesList[0]?.items[0]?.month} {new Date().getFullYear()} */}
                        </MDTypography>
                    </MDBox>
                </StyledMdBoxMain>
                <MDBox pt={3} pb={2} px={2}>
                    <MDBox
                        component="ul"
                        display="flex"
                        flexDirection="column"
                        p={0}
                        m={0}
                        sx={{ listStyle: "none" }}>
                        {isLoading
                            ?
                            <CircularProgress color="inherit" size={16} />
                            : <>
                                {data?.results?.map(e => {
                                    return (<>
                                        <MDBox mb={2}>
                                            <MDTypography variant="caption" color="text" fontWeight="bold" textTransform="uppercase">
                                                {e.acquiredAtString}
                                            </MDTypography>
                                            <Transaction
                                                color={e.type == 1 ? "success" : "error"}
                                                name={e.ticker ?? e.name}
                                                // description={e.makeDateString}
                                                value={`R$ ${e.type == 1 ? (e.price * e.quantity).toFixed(2) : '-' + (e.price * e.quantity).toFixed(2)}`}
                                                id={e.id}
                                                image={e.image}
                                                customAssetIcon={e.assetTypeId == 7}
                                                flexDirection="row"
                                                // openSnackBar={(e) => openSnackBar(e)}
                                                onOpenEditModal={(idx) => handleOpenModal(idx)}
                                                quantity={e.quantity}
                                            />
                                        </MDBox>
                                    </>)
                                })}
                            </>}
                    </MDBox>
                </MDBox>
            </Card>

            <KeepMountedModal
                title="Editar Operação"
                content={<EditOperationForm
                    openSnackBar={(e) => setOpenSnackMessage(e)}
                    title="Editar Operação"
                    id={id}
                    closeModal={handleCloseModal}
                    refreshList={() => setReloadList(!reloadList)}
                />}
                closeModal={handleCloseModal}
                isOpen={openModal} // Passa o estado diretamente
            />
            <Grid item xs={12} sm={6} lg={3}>
                <MDSnackbar
                    color={openSnackMessage?.type}
                    icon="check"
                    title={openSnackMessage?.message}
                    open={openSnackMessage}
                    onClose={() => setOpenSnackMessage(false)}
                    close={() => setOpenSnackMessage(false)}
                // bgWhite
                />
            </Grid>
            {data?.pageCount > 1 && (
                <StyledPagination
                    sx={{ display: 'flex', justifyContent: 'center', paddingTop: 4 }}
                    disabled={isLoading}
                    count={data?.pageCount}
                    boundaryCount={1}
                    onChange={(e, page) => setCurrentPage(page)}
                    size="small"
                />
            )}

        </DashboardLayout>
    )
}

const StyledPagination = styled(Pagination)`
    .MuiPagination-ul button{
        background: radial-gradient(circle at 225% 577%, #f28371 0%, #f28371 84%, transparent 0%),#F0BA8E!important;
        opacity: 0.8; 

        &[disabled]{
            opacity: 0.4; 
        }

        &.Mui-selected {
            opacity: 0.9; 
            background: radial-gradient(circle at -136% 568%, #f28371 0%, #f28371 98%, transparent 0%),#F0BA8E!important
        }
    }

    .MuiPagination-ul li{
        padding: 0 2px;
    }
`;

const StyledMdBoxMain = styled(MDBox)`

@media screen and (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start !important;

        .search-input-div{
            padding-right: 16px;
            padding-left: 0;
       }

        div.mdp-day-picker{
            padding: 5px !important;
        }
  }

  @media screen and (max-width: 444px) {
    &>div{
        padding-left: 0;
        flex-direction: column;
        width: 100%;
    }

   .search-input-div{
        width: 100%;

        padding-right: 10px;
        padding-left: 0;

        & div{
            width:100%
        }
   }

   input:nth-child(2){
        right: 41%;
        position: relative;
        width: 100%;
    }

   .calendar-input-div{
        width: 100%;
        padding-right: 10px;

        &>div:nth-child(1){
            width: 100%;
        }
    }

    .mother{
        width: 100%;
    }

   .rmdp-container {
        width: 100%;
   }

   .rmdp-input{
        width:100%;
        margin: 0;
   }

   .icon-tabler-calendar-event{
        left: 0;
   }
}

`;

export default OperationsExtratoLayout;