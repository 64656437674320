import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useMaterialUIController } from 'context';
import styled, { css } from 'styled-components';

function SearchInput({ handleSearchChange }) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const [search, setSearch] = useState('');

    useEffect(() => {
        handleSearchChange(search);
    }, [search])

    return (
        <TextField
            variant="standard"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <StyledSearchIcon $isDarkTheme={darkMode} />
                    </InputAdornment>
                ),
            }}
        />
    );
};

const StyledSearchIcon = styled(SearchIcon)` 
        ${({ $isDarkTheme }) =>
        css`
            fill: ${$isDarkTheme ? 'rgba(255, 255, 255, 0.8) !important' : 'rgb(123, 128, 154) !important'}  ;
              `}
`;

export default SearchInput;
