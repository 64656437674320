import CustomFetch from "components/API/customFetch";

export const CreatePaymentIntent = async (requestBody) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestBody)
    };
    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/Payment/create-checkout-intent`, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    };
};

//TODO: Ver se isso aqui vai ser preciso
export const UpdateUserPaymentDetails = async () => {
    const requestOptions = {
        method: 'PUT',
        // body: JSON.stringify(requestBody)
    };
    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/Payment/user-payment-details`, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    };
};


export default CreatePaymentIntent;