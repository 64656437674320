import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layouts/dashboard/components/Projects/data";

// Api
import MDAvatar from "components/MDAvatar";
import { Link, json } from "react-router-dom";
import styled from "styled-components";
import getAssets from "layouts/dashboard/api/dashboardApi";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import FormPontuarAtivo from "./formPontuarAtivo";
import KeepMountedModal from "components/Modal";
import MDSnackbar from "components/MDSnackbar";
import { Grid, Pagination } from "@mui/material";
import { getAssetsScoreList } from "../../api/allocationApi";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { getTotalPropertyValue } from "layouts/dashboard/api/dashboardApi";

function PontuarAtivos() {
  const { columns, rows } = data();
  const [menu, setMenu] = useState(null);
  const [assets, setAssets] = useState([]);
  const [assetsScoreList, setAssetsScoreList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [selectedAssetName, setSelectedAssetName] = useState('');
  const [openSnackMessage, setOpenSnackMessage] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPropertyValue, setTotalPropertyValue] = useState(0);
  const [orderBy, setOrderBy] = useState(null);
  const [orderDirection, setOrderDirection] = useState(null);

  const ITEMS_PER_PAGE = 20;

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  useEffect(() => {
    getTotalPropertyValuex();
  }, [])

  useEffect(() => {
    //TODO: aqui chama a API para buscar a posição atual do user;
    getAssetsx();
    getAssetsScoreListx();
  }, [, currentPage, orderBy, orderDirection]);


  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const getTotalPropertyValuex = async () => {
    await getTotalPropertyValue().then((data) => {
      setTotalPropertyValue(data);
    });
  }

  const getAssetsx = async () => {
    setIsLoading((isLoading) => !isLoading)
    await getAssets(ITEMS_PER_PAGE, currentPage, orderBy, orderDirection).then((data) => {
      setAssets(data.results);
      setPageCount(data.pageCount);
      setIsLoading((isLoading) => !isLoading)
    });
  };

  const getAssetsScoreListx = async () => {
    await getAssetsScoreList().then((data) => {
      setAssetsScoreList(data)
    });
  }

  const refreshList = () => {
    getAssetsx();
    getAssetsScoreListx();
  }

  const columnsx = [
    { Header: "Empresa", accessor: "logo", width: "25%", align: "left" },
    // { Header: "Quantidade", accessor: "quantity", width: "10%", align: "center" },
    // { Header: "PM", accessor: "averagePrice", align: "center" },
    // { Header: "Valor de mercado", accessor: "marketPrice", align: "center" },
    { Header: "Total atual / Meta", accessor: "total", align: "center" },
    { Header: "Percentual atual / meta", accessor: "walletPercentage", align: "center" },
    { Header: "Nota do ativo", accessor: "score", align: "center", blockSorting: true },
  ];

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" justifyContent="start" lineHeight={1}>
      <MDAvatar src={image} bgColor={'dark'} name={name} size="sm" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const openFormToGivePoints = (id, name) => {
    setSelectedAssetId(id);
    setSelectedAssetName(name);
    setIsOpen(true);
  }

  const targetTotalValue = (e) => {
    const assetScore = assetsScoreList.filter(x => x.assetId == e.assetId)[0]?.score ?? 0;
    const assetsScoreTotal = assetsScoreList.reduce((prev, curr) => prev + curr.score, 0);

    const result = (e.total + (((assetScore / (assetsScoreTotal == 0 ? 1 : assetsScoreTotal) * 100) - e.walletPercentage) / 100)
      * totalPropertyValue).toFixed(2);

    return result > 0 ? `R$ ${result}` : '-';
  }

  const getDifferenceTargetValue = (e) => {
    const assetScore = assetsScoreList.filter(x => x.assetId == e.assetId)[0]?.score ?? 0;
    const assetsScoreTotal = assetsScoreList.reduce((prev, curr) => prev + curr.score, 0);

    const result = (
      (
        (
          (assetScore / (assetsScoreTotal == 0 ? 1 : assetsScoreTotal) * 100
          ) - e.walletPercentage
        ) / 100
      )
      * totalPropertyValue).toFixed(2);

    if (assetScore == 0) return null;

    return result > 0 ? <span style={{ color: 'green', fontSize: 12 }}>(Faltam R$ {result}, para atingir a meta)</span> : <span style={{ color: 'red', fontSize: 12 }}>(o valor está acima da meta em R$ {result * -1})</span>;
  }

  const getGoalTargetPercentage = (e) => {
    const assetScore = assetsScoreList.filter(x => x.assetId == e.assetId)[0]?.score ?? 0;
    const assetsScoreTotal = assetsScoreList.reduce((prev, curr) => prev + curr.score, 0);

    const result = (assetScore / (assetsScoreTotal == 0 ? 1 : assetsScoreTotal) * 100).toFixed(2);

    return result > 0 ? `${result}%` : '-';
  }

  const sortData = (val) => {
    if (val != orderBy) {
      setOrderBy(val);
      setOrderDirection('asc')
      return;
    }

    if (orderDirection == 'asc') {
      setOrderDirection('desc');
      return;
    }

    if (orderDirection == 'desc') {
      setOrderDirection(null);
      return;
    }

    if (orderDirection == null) {
      setOrderDirection('asc');
      return;
    }
  };

  const rowsx = assets.map(e => (
    {
      name: e.name,
      assetId: e.assetId,
      logo: <Company image={e.logo} name={e.ticker} />,
      quantity: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {e.quantity}
        </MDTypography>),
      averagePrice: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          R$ {e.averagePrice.toFixed(2)}
        </MDTypography>
      ),
      marketPrice: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          R$ {e.marketPrice ? e.marketPrice?.toFixed(2) : '-'}
        </MDTypography>
      ),
      total: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Atual: R$ {e.total.toFixed(2)} <br /> Meta: {targetTotalValue(e)} <br /> {getDifferenceTargetValue(e)}
        </MDTypography>
      ),
      walletPercentage: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Atual: {e.walletPercentage.toFixed(2)}% <br /> Meta: {getGoalTargetPercentage(e)}
        </MDTypography>
      ),
      score: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {assetsScoreList.filter(x => x.assetId == e.assetId)[0]?.score}
        </MDTypography>
      )
    }
  ));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card className="tabelaAtivos">
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        </MDBox>
        <MDBox>
          <KeepMountedModal
            isOpen={isOpen}
            closeModal={handleCloseModal}
            content={
              <FormPontuarAtivo
                title="Configuração - Pontuar Ativo"
                assetId={selectedAssetId}
                asstName={selectedAssetName}
                refreshList={refreshList}
                openSnackBar={(e) => setOpenSnackMessage(e)}
                closeModal={handleCloseModal}
              />}
          />
          <DataTable
            table={{ columns: columnsx, rows: rowsx }}
            showTotalEntries={false}
            isSorted={true}
            noEndBorder
            entriesPerPage={false}
            itemsPerPage={ITEMS_PER_PAGE}
            expandable
            clickableRow={(assetId, assetName) => openFormToGivePoints(assetId, assetName)}
            // sortTable={sortTableDebounce}
            sortData={sortData}
          />
        </MDBox>
      </Card>
      <Grid item xs={12} sm={6} lg={3}>
        <MDSnackbar
          color={openSnackMessage?.type}
          icon="check"
          title={openSnackMessage?.message}
          open={openSnackMessage}
          onClose={() => setOpenSnackMessage(false)}
          close={() => setOpenSnackMessage(false)}
        />
      </Grid>
      {pageCount > 1 && (
        <StyledPagination
          sx={{ display: 'flex', justifyContent: 'center', paddingTop: 4 }}
          disabled={isLoading}
          count={pageCount}
          boundaryCount={1}
          onChange={(e, page) => setCurrentPage(page)}
          size="small"
        />
      )}
    </DashboardLayout>
  );
}

const StyledLink = styled(Link)`
  color: #7B809A;
`;

const StyledPagination = styled(Pagination)`
    .MuiPagination-ul button{
        background: radial-gradient(circle at 225% 577%, #f28371 0%, #f28371 84%, transparent 0%),#F0BA8E!important;
        opacity: 0.8; 

        &[disabled]{
            opacity: 0.4; 
        }

        &.Mui-selected {
            opacity: 0.9; 
            background: radial-gradient(circle at -136% 568%, #f28371 0%, #f28371 98%, transparent 0%),#F0BA8E!important
        }
    }

    .MuiPagination-ul li{
        padding: 0 2px;
    }
`;

export default PontuarAtivos;
