import { useMaterialUIController } from 'context';
import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

const GraficoMeta = ({ data }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const COLORS = [
        "rgba(3, 169, 244, 0.8)", // Azul claro com 80% de opacidade
        "rgba(76, 175, 80, 0.8)", // Verde vibrante com 80% de opacidade
        "rgba(255, 193, 7, 0.8)", // Amarelo quente com 80% de opacidade
        "rgba(255, 87, 34, 0.8)", // Laranja intenso com 80% de opacidade
        "rgba(156, 39, 176, 0.8)", // Roxo médio com 80% de opacidade
        "rgba(244, 67, 54, 0.8)", // Vermelho forte com 80% de opacidade
        "rgba(33, 150, 243, 0.8)", // Azul médio com 80% de opacidade
        "rgba(121, 85, 72, 0.8)", // Marrom suave com 80% de opacidade
        "rgba(63, 81, 181, 0.8)", // Azul escuro com 80% de opacidade
        "rgba(255, 235, 59, 0.8)", // Amarelo vibrante com 80% de opacidade
        "rgba(0, 150, 136, 0.8)", // Verde-água com 80% de opacidade
        "rgba(103, 58, 183, 0.8)", // Roxo escuro com 80% de opacidade
    ];

    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 1.1; // Ajustado para 1.1
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill={darkMode ? "#ffffffcc" : "#344767"}
                fontSize={10}
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {`${data[index].name} ${(percent * 100).toFixed(2)}%`}
            </text>
        );
    };

    const renderCustomLabelLine = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.96; // Linha no mesmo ponto do rótulo
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        const lineEndX = cx + (outerRadius + 15) * Math.cos(-midAngle * RADIAN); // Ajustado para 15px além do outerRadius
        const lineEndY = cy + (outerRadius + 15) * Math.sin(-midAngle * RADIAN);

        return (
            <line
                x1={x}
                y1={y}
                x2={lineEndX}
                y2={lineEndY}
                stroke={darkMode ? "#ffffffcc" : "#344767"}
                strokeWidth={1}
            />
        );
    };

    return (
        <div style={{ width: '100%', height: 500 }}>
            <ResponsiveContainer>
                <PieChart>
                    <Pie
                        dataKey="value"
                        data={data}
                        fill="#8884d8"
                        label={renderCustomizedLabel}
                        labelLine={renderCustomLabelLine}
                    >
                        {data?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default GraficoMeta;
