import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDInput
import MDInputRoot from "components/MDInput/MDInputRoot";
import styled from "styled-components";

const MDInput = forwardRef(({ error, errorMessage, success, disabled, ...rest }, ref) => (
  <>
    <MDInputRoot {...rest} ref={ref} ownerState={{ error, success, disabled }} />
    {(error && errorMessage) &&
      <StyledSpan>
        {errorMessage}
      </StyledSpan>}
  </>
));

// Setting default values for the props of MDInput
MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the MDInput
MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

const StyledSpan = styled.span`
  color: red;
  font-size:10px
`;

export default MDInput;
