import CustomFetch from "components/API/customFetch";

export const updateAllocationTarget = async (requestBody) => {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(requestBody)
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/Allocation`, requestOptions);

        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }
        
        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    };
};

export const getAllocationTarget = async () => {
    const requestOptions = {
        method: 'GET',
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/Allocation/target`, requestOptions);

        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    };
};

export const getCurrentAllocation = async () => {
    const requestOptions = {
        method: 'GET',
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/Allocation/current`, requestOptions);

        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    };
};

export const updateAssetScore = async (requestBody) => {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(requestBody)
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/Allocation/asset-score`, requestOptions);

        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }
        
        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    };
};


export const getAssetScore = async (assetId) => {
    const requestOptions = {
        method: 'GET'
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/Allocation/asset-score?assetId=${assetId}`, requestOptions);

        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }
        
        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    };
};


export const getAssetsScoreList = async () => {
    const requestOptions = {
        method: 'GET',
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/Allocation/asset-score-list`, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    }
};

export const getAssetsCurrentAndTargetList = async () => {
    const requestOptions = {
        method: 'GET',
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/Allocation/asset-current-target-list`, requestOptions);
        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    }
};

export default updateAllocationTarget;