
import { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import gregorian_pt_br from "react-date-object/locales/gregorian_pt_br"
import colors from "assets/theme/base/colors";
import styled from "styled-components";
import "react-multi-date-picker/styles/layouts/mobile.css"
import InputIcon from "react-multi-date-picker/components/input_icon"
import { useMaterialUIController } from "context";

function DateRangePicker({ setDateRange, okButtonClicked, resetValues }) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const [value, setValue] = useState({ startDate: null, endDate: null });

    const applyHoverEffect = (button) => {
        button.addEventListener('mouseover', () => {
            button.style.background = 'rgb(126, 166, 240)'; // Cor de hover
            button.style.borderRadius = '25px';
            button.style.color = '#FFFFFF'

        });
        button.addEventListener('mouseout', () => {
            button.style.background = 'none'; // Reseta a cor
            button.style.color = '#0074d9'
        });
    };


    const forceClick = () => {
        const element = document.querySelector('.jaozin'); 
        if (element) {
            element.click(); 
        }
    };

    const setOkButton = () => {

        const applyHoverEffect = (button) => {
            button.addEventListener('mouseover', () => {
                button.style.background = 'rgb(126, 166, 240)'; 
                button.style.borderRadius = '25px';
                button.style.color = '#FFFFFF'

            });
            button.addEventListener('mouseout', () => {
                button.style.background = 'none'; 
                button.style.color = '#0074d9'
            });
        };

        // Cria o contêiner para os botões "Cancelar" e "Ok"
        const buttonsContainer = document.createElement('div');
        buttonsContainer.style.display = 'flex';
        buttonsContainer.style.gap = '10px'; 
        buttonsContainer.style.position = 'absolute';
        buttonsContainer.style.right = '10px'; 

        // Seleciona o elemento onde você quer adicionar os botões
        const parentElement = document.querySelector('.rmdp-calendar>div:nth-child(2)');

        // Verifica se os botões já existem, se sim, não cria outros
        if (parentElement && parentElement.querySelector('#ok-button') && parentElement.querySelector('#cancel-button') && parentElement.querySelector('#reset-button')) {
            return; // Sai da função, pois os botões já existem
        }

        // Cria o botão OK dinamicamente
        const okButton = document.createElement('button');
        okButton.id = 'ok-button';  // Atribui um id único ao botão
        okButton.innerText = 'Ok';
        okButton.style.background = 'none';
        okButton.style.color = '#0074d9';
        okButton.style.cursor = 'pointer';
        okButton.style.fontSize = '13px';
        okButton.style.fontWeight = 500;
        okButton.style.border = 'none';
        okButton.style.fontFamily = 'Roboto, Helvetica, Arial, sans-serif';
        okButton.style.padding = '5px';
        applyHoverEffect(okButton);

        // Adiciona o evento de clique no botão OK
        okButton.addEventListener('click', function () {
            okButtonClicked();
            forceClick();
        });

        // Cria o botão Cancel dinamicamente
        const cancelButton = document.createElement('button');
        cancelButton.id = 'cancel-button';  // Atribui um id único ao botão
        cancelButton.innerText = 'Cancelar';
        cancelButton.style.background = 'none';
        cancelButton.style.color = '#0074d9';
        cancelButton.style.fontWeight = 500;
        cancelButton.style.cursor = 'pointer';
        cancelButton.style.fontSize = '13px';
        cancelButton.style.border = 'none';
        cancelButton.style.fontFamily = 'Roboto, Helvetica, Arial, sans-serif';
        cancelButton.style.padding = '5px';
        applyHoverEffect(cancelButton);

        // Adiciona o evento de clique no botão Cancel para fechar o calendário
        cancelButton.addEventListener('click', function () {
            forceClick();
            setValue({ startDate: null, endDate: null });
        });

        // Cria o botão Reset dinamicamente
        const resetButton = document.createElement('button');
        resetButton.id = 'reset-button';  // Atribui um id único ao botão
        resetButton.innerText = 'Reset';
        resetButton.style.fontWeight = 500;
        resetButton.style.color = '#0074d9';
        resetButton.style.background = 'none';
        resetButton.style.cursor = 'pointer';
        resetButton.style.fontSize = '13px';
        resetButton.style.border = 'none';
        resetButton.style.position = 'absolute';
        resetButton.style.left = '10px';
        resetButton.style.fontFamily = 'Roboto, Helvetica, Arial, sans-serif';
        resetButton.style.padding = '5px';
        applyHoverEffect(resetButton);

        // Adiciona o evento de clique no botão Reset para limpar o intervalo de datas
        resetButton.addEventListener('click', function () {
            forceClick();
            setValue({ startDate: null, endDate: null });  // Reseta o state
            setTimeout(() => {
                resetValues();
            }, 0);
        });

        buttonsContainer.appendChild(cancelButton);
        buttonsContainer.appendChild(okButton);

        // Insere os botões no DOM como filhos do parentElement
        parentElement?.appendChild(resetButton);
        parentElement?.appendChild(buttonsContainer);
    }

    useEffect(() => {
        if (value != null) {
            setDateRange(
                {
                    startDate: value[0]?.format("YYYY-MM-DD"),
                    endDate: value[1]?.format("YYYY-MM-DD")
                })
        }
        else {
            setDateRange({ start: null, end: null });
        }
    }, [value])

    return (
        <StyledMainDiv>
            <StyledDiv $darkMode={darkMode} className="mother"
                onClick={() => setTimeout(() => {
                    setOkButton();
                }, 1) }
            >
                <StyledDatePicker
                    value={value}
                    onChange={setValue}
                    range
                    format="DD/MM/YYYY"
                    render={<InputIcon /> }
                    locale={gregorian_pt_br}
                    color={colors}
                // placeholder="Data"

                />
            </StyledDiv>
            <div class="jaozin" hidden></div>
        </StyledMainDiv>
    )
}

const StyledMainDiv = styled.div`
    display: flex;
    align-items: center;
`;

const StyledDiv = styled.div`
input{
    height: 28px;
    color: ${({ $darkMode }) => ($darkMode ? colors.grey[600] :  colors.grey[700])};
    font-size:14px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight :400;   
    border: none;
    border-radius: 0;
    border-bottom: ${({ $darkMode }) => ($darkMode ? `1px solid #8b909d` : `1px solid ${colors.inputBorderColor}`)};
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 10px;
    vertical-align: top;

    &::placeholder{
        color: ${() => colors.grey[500]};
    }
}

.rmdp-input:focus{
    border: none;
    box-shadow: none;
    border-bottom: 2px solid ${() => colors.info.main};
}

.rmdp-container {
    position: relative;

  }

.rmdp-wrapper{
    position: relative;
    right: 50px;
}

.ep-arrow{
    left: -50px !important;
}

.rmdp-input {
    padding-left: 22px; /* Espaço para o ícone à esquerda */
    background: transparent;
  }
  
.icon-tabler-calendar-event {
    position: absolute;
    left: 13px; /* Mover o ícone para a esquerda */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: cursor; /* Faz o ícone não interferir na interação do input */
    width: 13px;
  }

  @media screen and (max-width: 444px) {
    .rmdp-wrapper{
        // position: relative;
        right: 0px;
    }
    
    .ep-arrow{
        left: 0px !important;
    }
}
`;

const StyledDatePicker = styled(DatePicker)`
//    .rmdp-input{
//         border: 10px;
//         // background: red;
//          background-color: red;
//    }

   .rmdp-calendar  {
        padding-bottom: 30px;
   }
`;

export default DateRangePicker;