// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import { useEffect, useState } from "react";
import styled from "styled-components";
function ProductsList(props) {
    const {selectedPlan} = props;
    return (
        <StyledContainer>
            <div className="plano-box">
                <div className="item-wrapper">
                    {/* Cabeçalho do Plano */}
                    <div className="item-head">
                        <h5
                            className="item-title mbr-fonts-style mb-0 display-5"
                            data-app-selector=".item-title"
                        >
                            <b>Gratis</b>
                        </h5>
                        <h6
                            className="item-subtitle mbr-fonts-style mt-0 mb-0 display-7"
                            data-app-selector=".item-subtitle"
                        >
                            <b>R$ 0.00</b>/mês
                        </h6>
                    </div>
                    {/* Conteúdo do Plano */}
                    <div className="item-content">
                        <p>
                            Controle receitas, despesas e visualize gráficos básicos sem custos.
                            Ideal para quem está começando a gerenciar suas finanças.
                        </p>
                        <br />
                        <p className="mbr-text mbr-fonts-style display-7">
                            - ✅ Limite de até 5 ativos
                        </p>
                        <p className="mbr-text mbr-fonts-style display-7">
                            - ✅ Limite de até 25 transações (receita x despesas) / mês
                        </p>
                        <p className="mbr-text mbr-fonts-style display-7">
                            - ❌ Suporte técnico personalizado
                        </p>
                        <p className="mbr-text mbr-fonts-style display-7">
                            - ❌ Rebalanceador de carteira
                        </p>
                        <p className="mbr-text mbr-fonts-style display-7">
                            - ❌ Calculadora automática de aportes e/ou reinvestimento
                        </p>
                    </div>
                    {/* Botão de Ação */}
                    <div className="mbr-section-btn item-footer">
                        <a
                            href="https://app.patrimonio360.com.br/dashboard"
                            className="btn item-btn btn-primary display-7"
                        >
                            Escolher plano
                        </a>
                    </div>
                </div>
            </div>
            <div className="plano-box">
                <div className="item-wrapper">
                    {/* Cabeçalho do Plano */}
                    <div className="item-head">
                        <h5 className="item-title mbr-fonts-style mb-0 display-5">
                            <b>Standard</b>
                        </h5>
                        <h6 className="item-subtitle mbr-fonts-style mt-0 mb-0 display-7">
                            <b>R$ 19.90</b>/mês
                        </h6>
                    </div>
                    {/* Conteúdo do Plano */}
                    <div className="item-content">
                        <p>
                            Controle receitas, despesas e visualize gráficos básicos sem custos.
                            Ideal para quem está começando a gerenciar suas finanças.
                        </p>
                        <br />
                        <p className="mbr-text mbr-fonts-style display-7">
                            - ✅ Sem limite de ativos
                        </p>
                        <p className="mbr-text mbr-fonts-style display-7">
                            - ✅ Sem limite de transações (receita x despesas) / mês
                        </p>
                        <p className="mbr-text mbr-fonts-style display-7">
                            - ❌ Suporte técnico personalizado
                        </p>
                        <p className="mbr-text mbr-fonts-style display-7">
                            - ❌ Rebalanceador de carteira
                        </p>
                        <p className="mbr-text mbr-fonts-style display-7">
                            - ❌ Calculadora automática de aportes e/ou reinvestimento
                        </p>
                    </div>
                    {/* Botão de Ação */}
                    <div className="mbr-section-btn item-footer">
                        <div onClick={() => selectedPlan(2)}
                          
                            className="btn item-btn btn-primary display-7"
                        >
                            Escolher plano
                        </div>
                    </div>
                </div>
            </div>
            <div className="plano-box">
                <div className="item-wrapper">
                    {/* Cabeçalho do Plano */}
                    <div className="item-head">
                        <h5 className="item-title mbr-fonts-style mb-0 display-5">
                            <b>Pro</b>
                        </h5>
                        <h6 className="item-subtitle mbr-fonts-style mt-0 mb-0 display-7">
                            <b>R$ 49.90</b>/mês
                        </h6>
                    </div>
                    {/* Conteúdo do Plano */}
                    <div className="item-content">
                        <p>
                            Ideal para quem está procura estratégias inteligentes e automatizadas de gestão, alocação e rebalancemento.
                        </p>
                        <br />
                        <p className="mbr-text mbr-fonts-style display-7">
                            - ✅ Sem limite de ativos
                        </p>
                        <p className="mbr-text mbr-fonts-style display-7">
                            - ✅ Sem limite de transações (receita x despesas) / mês
                        </p>
                        <p className="mbr-text mbr-fonts-style display-7">
                            - ✅ Rebalanceador de carteira
                        </p>
                        <p className="mbr-text mbr-fonts-style display-7">
                            - ✅ Calculadora automática de aportes e/ou reinvestimento
                        </p>
                        <p className="mbr-text mbr-fonts-style display-7">
                            - ✅ Suporte técnico personalizado
                        </p>
                    </div>
                    {/* Botão de Ação */}
                    <div className="mbr-section-btn item-footer">
                        <div onClick={() => selectedPlan(3)}
                            className="btn item-btn btn-primary display-7"
                        >
                            Escolher Plano
                        </div>
                    </div>
                </div>
            </div>
        </StyledContainer>
    );
}
const StyledContainer = styled.div`
    padding: 100px 10px;
   
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .item-wrapper {
        border-radius: 2rem;
        overflow: hidden;
        margin-bottom: 2rem;
        background: #ffffff;
        padding: 0rem;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
    }
    .item-head {
        background: radial-gradient(circle at 135% 651%, #f28371 79%, #f28371 60%, transparent 0%), #f0ba8d !important;
        padding: 2.25rem;
    }
    
    .item-content {
        padding: 2.25rem 2.25rem 0;
        background: #ffffff;
    }
    .item-title {
        text-align: left;
        color: var(--primary-text, #ffffff);
    }
   .item-subtitle {
        text-align: left;
        color: var(--primary-text, #ffffff);
    }
   .item-wrapper .item-footer {
        padding: 0 2rem 2rem;
    }
    .btn-primary, .btn-primary:active {
        background-color: #f0c808 !important;
        border-color: #f0c808 !important;
        color: #ffffff !important;
        box-shadow: none;
    }
    .mbr-section-btn .btn:not(.btn-form) {
        border-radius: 100px;
    }
    .btn {
        padding: 1.25rem 2rem;
        width: -webkit-fill-available;
        font-weight: 600;
        border-width: 1px;
        font-style: normal;
        margin: 0.6rem 0.6rem;
        white-space: normal;
        transition: all 0.2s ease-in-out;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        word-break: break-word;
    }
    .btn:hover {
       color:red;
    }
    .display-7 {
        font-family: 'Golos Text', sans-serif;
        font-size: 1.4rem;
        line-height: 1.3;
    }
    .plano-box{
        max-width: 500px;
        padding: 10px;
    }
    
    .btn.item-btn.btn-primary.display-7:hover{ 
        color: #f28371 !important;
        background-color:#f0ba8d  !important;
        cursor: pointer;
    }
    
    @media (max-width: 767px) {
        // padding: 100px 200px;
    }
    `;
    
export default ProductsList;