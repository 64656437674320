import CustomFetch from "components/API/customFetch";

export const getCacluatorData = async (investiment, strategyType) => {
    const requestOptions = {
        method: 'GET',
    };

    try {
        const response = await CustomFetch(`${process.env.REACT_APP_API}/Allocation/reinvestment-calculator?investiment=${investiment}&strategyType=${strategyType}`, requestOptions);

        if (!response.ok) {
            throw new Error('Erro ao enviar requisição:', response.status);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro:', error);
        throw error;
    };
};

export default getCacluatorData;