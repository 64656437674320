
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import StripeCheckoutForm from "components/Payment/StripeCheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from "react";
import styled from "styled-components";
import ProductsList from "./ProductsList";

function Payment() {
    const [plan, setPlan] = useState(null);
    const selectedPlan = (plan) => {
        setPlan(plan)
    }
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    const options = {
        locale: 'pt-BR',
        mode: 'payment',
        amount: 1099, // Amount in cents (e.g., R$10.99)
        currency: 'brl',
        //aparencia;
        appearance: {
            theme: 'flat', // Base theme
            variables: {
                colorPrimary: '#4caf50',
                colorBackground: '#f9f9f9',
                colorText: '#000000',
                colorDanger: '#f44336',
                fontFamily: 'Arial, sans-serif',
                spacingUnit: '3px',
                borderRadius: '6px',
            },
            rules: {
                '.Input': {
                    color: '#333333',
                    backgroundColor: '#ffffff',
                    boxShadow: 'none',
                    border: '1px solid #cccccc',
                    borderRadius: '6px',
                },
                '.Label': {
                    color: '#555555',
                    fontSize: '14px',
                    fontWeight: 'bold',
                },
                '.Tab': {
                    backgroundColor: '#ffffff',
                    border: '1px solid #e0e0e0',
                    padding: '8px',
                    borderRadius: '6px',
                },
                '.Tab:hover': {
                    backgroundColor: '#f0f0f0',
                },
                '.Error': {
                    color: '#f44336',
                    fontSize: '12px',
                },
            },
        },
    };
    return (
        <StyledContainer>
            <ProductsList selectedPlan={selectedPlan} />
            <StyledValueDiv>
                {/* <StyledSpan> */}
                R${plan == 2 ? '19,90' : plan == 3 ? '49,90' : '0,00'}
                {/* </StyledSpan> */}
            </StyledValueDiv>
            <StyledMdBox py={3}>
                {plan != null && (
                    <Elements stripe={stripePromise} options={options}>
                        <StripeCheckoutForm planId={plan} amountValue={plan == 2 ? 1990 : plan == 3 ? 4990 : 0} />
                    </Elements>
                )}
            </StyledMdBox>
        </StyledContainer>
    );
}
const StyledSpan = styled.span`
`;
const StyledValueDiv = styled.div`
    display:flex;
    justify-content: center;
    font-size: 3rem;
    color: #0000007d;
    margin-bottom: 25px;
`;
const StyledMdBox = styled(MDBox)`
padding: 100px 200px;
@media (max-width: 767px) {
     padding: 0;
}
`;
const StyledContainer = styled.div`
    // 
    
    button[type="submit"]::before {
        // content: 'pagar agora!';
    }
    button[type="submit"] {
        color: #fff;
        font-weight: 600;
        background-color: rgb(76, 175, 80);
        padding: 10px;
        border-radius: 5px;
        border: solid 1px #000;
    }
`;

export default Payment;