// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import ReceitaXDespesa from "layouts/receitaXdespesa/components/receitasxdespesas";
import ExtratoConsolidado from "layouts/receitaXdespesa/components/ExtratoConsolidado";
import Transactions from "layouts/receitaXdespesa/components/Transactions";
import { getRevenueExpensesSummary } from "layouts/receitaXdespesa/components/receitasxdespesas/revenueExpensesApi";
import { useEffect, useState } from "react";
import { Steps } from "intro.js-react";
import MDSnackbar from "components/MDSnackbar";


function Billing(props) {
  const { remaningValue } = props;
  const [revenueXExpenses, setRevenueXExpenses] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const [screenWidth, setScreenWidth] = useState(null);
  const [openSnackMessage, setOpenSnackMessage] = useState(null);
  const [refreshList, setRefreshList] = useState(false);
  const [refresSummaryBoxes, setRefresSummaryBoxes] = useState(false);

  useEffect(() => {
    setScreenWidth(getWindowDimensions().width)

    getRevenueExpensesSummaryMethod();
    const onboarding = localStorage.getItem("patrimonio360onboarding-revenuexexpenses");
    setStepsEnabled(onboarding != 'true');
  }, []);

  useEffect(() => {
    getRevenueExpensesSummaryMethod()
  }, [refreshList, refresSummaryBoxes])

  const getRevenueExpensesSummaryMethod = async () => {
    await getRevenueExpensesSummary().then((data) => {
      setRevenueXExpenses(data);
      setIsLoading(false);
    })
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const handleOpenSnackBar = (e) => {
    setOpenSnackMessage({ type: e?.type, message: e?.message, isUpgradeMessage: e?.isUpgradeMessage });
  }

  const stepsDesktop = [
    {
      element: '.menu-receitaxdespesa',
      intro: 'No Menu "Receita x Despesa", você pode inserir os seus ganhos e gastos',
    },
    {
      element: '.receitas',
      intro: 'Este é o valor somado de todas as receitas(ganhos) deste mês',
      // position: 'right',
      // tooltipClass: 'myTooltipClass',
    },
    {
      element: '.despesas',
      intro: 'Este é o valor somado de todas as despesas(gastos) deste mês',
    },
    {
      element: '.saldo',
      intro: 'Esta caixa mostra seu saldo disponível deste mês',
    },
    {
      element: '.addRevenueExpense',
      intro: 'Vamos começar! Adicione uma nova Receita ou Despesa',
    },
  ];

  const handleBeforeChange = (nextStepIndex) => {
    if (screenWidth < 1200 && nextStepIndex == 0) {
      setTimeout(() => {
        document.getElementsByClassName("menuHamburger")[0].click()

      }, 100);
    }

    if (screenWidth < 1200 && nextStepIndex == 1) {
      document.getElementsByClassName("menuHamburger")[0].click()
    }
  };

  return (
    <DashboardLayout>
      <Steps
        enabled={stepsEnabled}
        steps={stepsDesktop}
        initialStep={0}
        onExit={() => setStepsEnabled(false)}
        onBeforeChange={handleBeforeChange}
        onBeforeExit={false}
        exitOnOverlayClick={false}
        onComplete={() => localStorage.setItem("patrimonio360onboarding-revenuexexpenses", true)}
        options={{
          doneLabel: 'Vamos começar!',
          prevLabel: '<',
          nextLabel: '>'
        }}
      />
      <Grid item xs={12} sm={6} lg={3}>
        <MDSnackbar
          color={openSnackMessage?.type}
          icon="check"
          title={openSnackMessage?.message}
          isUpgradeMessage={openSnackMessage?.isUpgradeMessage}
          open={openSnackMessage}
          onClose={() => setOpenSnackMessage(false)}
          close={() => setOpenSnackMessage(false)}
        // bgWhite
        />
      </Grid>
      <DashboardNavbar />
      <MDBox mt={3}>
        <MDBox mb={3} >
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3} xl={3}>
                  <DefaultInfoCard
                    classProp="receitas"
                    icon="trending_up"
                    title="Receitas"
                    description="Receitas deste mês"
                    value={
                      isLoading ? 'loading...'
                        :
                        new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(revenueXExpenses?.revenue)}
                    color={'success'}
                  />
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                  <DefaultInfoCard
                    classProp="despesas"
                    icon="trending_down"
                    title="Despesas"
                    description="Despesas deste mês"
                    value={
                      isLoading ? 'loading...'
                        :
                        new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(revenueXExpenses?.expenses)}
                    color={'error'}
                  />
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                  <DefaultInfoCard
                    classProp="saldo"
                    icon="account_balance_wallet"
                    title="Saldo"
                    description="Saldo"
                    value={
                      isLoading ? 'loading...'
                        :
                        new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(revenueXExpenses?.revenue - revenueXExpenses?.expenses)}
                    color={'dark'}
                  />
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                  <ReceitaXDespesa
                    openSnackBar={(e) => handleOpenSnackBar(e)}
                    closeOnboarding={setStepsEnabled}
                    remaningValue={revenueXExpenses?.remaningValue ?? '0'}
                    refreshList={() => setRefreshList(!refreshList)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={9}>
              <Transactions
                openSnackBar={(e) => handleOpenSnackBar(e)}
                hasNewTransaction={revenueXExpenses}
                refreshSummaryBoxes={() => setRefresSummaryBoxes(!refresSummaryBoxes)}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <ExtratoConsolidado />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
